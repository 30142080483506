<template>
  <BaseModal ref="modal" title="Lead toewijzen" max-width="tw-max-w-4xl">
    <p>Aan welke medewerker wens je deze lead toe te wijzen? Je zal automatisch als
      <strong>volger</strong> aan de lead worden gekoppeld.
    </p>
    <FormulateForm
      v-model="values"
      #default="{ isLoading }"
      name="assignmentForm"
      @submit="submit"
    >
        <FormulateInput
          type="autocomplete"
          auto-complete-type="collaborator"
          name="collaborator"
          label="Medewerker"
          placeholder="Selecteer medewerker"
          validation="required"
          @input="processBuyer"
        />
        <template v-if="isIntakeLead">
          <fieldset class="tw-m-0 tw-px-4 tw-border-gray-cc tw-rounded">
            <legend class="tw--mx-1 tw-my-0 tw-px-1 tw-w-auto tw-font-bold tw-text-sm tw-text-tg-color">
              Aanbreng (intakelead)
            </legend>
            <div class="tw-flex tw-flex-col sm:tw-flex-row tw-gap-2 tw-my-4">
              <div
                v-for="option in referralOptions"
                :key="option.id"
                class="w-full"
              >
                <input
                  v-model="values.referral_yes_no"
                  :id="option.id"
                  :value="option.value"
                  type="radio"
                  class="tw-peer tw-sr-only"
                  @change="setSubRadioButtonFields"
                />
                <label
                  class="
                    tw-flex tw-justify-center
                    tw-flex-wrap tw-bg-white tw-border tw-px-2
                    tw-border-gray-300 tw-rounded tw-text-grey-2
                    tw-text-sm tw-cursor-pointer focus:tw-outline-none
                    hover:tw-bg-gray-50 peer-checked:tw-text-black
                    peer-checked:tw-border-black
                    peer-checked:tw-font-bold
                    tw-w-full tw-py-2
                  "
                  :for="option.id"
                >
                  <span class="text-center">{{ option.label }}</span>
                </label>
              </div>
            </div>
            <FormulateInput
              v-if="values.referral_yes_no === 'referral_no'"
              type="radio"
              name="referral_no_selection"
              :options="{
                referral_no: 'Geen aanbreng/inkoop (spontane aanmelding klant, inkoop leidinggevende in exclusief gebied)',
                referral_by_lead: 'Inkoop van mezelf als leidinggevende (100% intakecommissie op transactie)'
              }"
              :validation-messages="{
                required: 'Selecteer een optie.'
              }"
              validation="required"
              outer-class="tw-my-0"
              :label-class="['tw-text-xs']"
              @input="processNoReferral"
            />
            <FormulateInput
              v-if="values.referral_yes_no === 'referral_yes'"
              type="radio"
              name="referral_yes_selection"
              :options="{
                referral_by_collaborator: 'Aanbreng van mezelf aan bovenstaande medewerker (50% intakecommissie op transactie)',
                referral_by_team: 'Aanbreng van een team (50% intakecommissie op transactie, verdeeld over de medewerkers hieronder)'
              }"
              validation="bail|required"
              :validation-messages="{
                required: 'Selecteer een optie.'
              }"
              outer-class="tw-my-0"
              :label-class="['tw-text-xs']"
              @input="processReferralByCollaborator"
            />
            <FormulateInput
              v-if="values.referral_yes_selection === 'referral_by_team'"
              type="autocomplete"
              auto-complete-type="poules"
              :multiple="true"
              name="referrers"
              label="Poules"
              validation="bail|required"
              placeholder="Selecteer poule"
              outer-class="md:tw-w-1/3 tw-mb-4"
              @input="processReferralByTeam"
            />
          </fieldset>
          <fieldset v-if="values.referral_no_selection || values.referral_yes_selection" class="tw-m-0 tw-mt-4 tw-px-4 tw-border-gray-cc tw-rounded">
            <legend class="tw--mx-1 tw-my-0 tw-px-1 tw-w-auto tw-font-bold tw-text-sm tw-text-tg-color">
              Pouleoverzicht
            </legend>
            <div>
              <div v-for="(poule, index) in pouleData" :key="index" class="tw-mb-3 last:tw-mb-1">
                <div>
                  <span v-if="poule.collaborator">{{ poule.collaborator.display_name }} - </span>{{ poule.role === 'buyer' ? 'Inkoper' : 'Aanbrenger' }}
                </div>
                <FormulateInput
                  v-if="poule.poules.length > 1"
                  type="select"
                  :name="`poule_${poule.collaborator.id}`"
                  :options="getPouleOptions(poule.poules)"
                  validation="required"
                  outer-class="tw-m-0 tw-w-1/3"
                />
                <span v-else-if="poule.poules.length === 0" class="tw-bg-red-500 tw-text-white tw-font-bold tw-mt-1 tw-inline-block tw-px-2 tw-py-1 tw-rounded">
                  Geen poule gevonden
                </span>
                <span v-else class="tw-bg-gray-500 tw-text-white tw-font-bold tw-mt-1 tw-inline-block tw-px-2 tw-py-1 tw-rounded">
                  {{ poule.poules[0].poule_name }}
                </span>
              </div>
            </div>
          </fieldset>
        </template>
        <div class="tw-flex tw-flex-col tw-items-end tw-w-full">
          <FormulateInput
            type="submit"
            :disabled="!values.collaborator || isLoading || !referralSelectionComplete"
            outer-class="tw-mt-4"
          >
            <i
              :class="[
                'fas tw-mr-2',
                isLoading ? 'fa-spinner-third fa-spin' : 'fa-save'
              ]"
            />
            Toewijzen
          </FormulateInput>
        </div>
    </FormulateForm>
  </BaseModal>
</template>

<script>
import { assignLead } from '@/services/sales'
import { errorModal, successModal } from '@/modalMessages'
import { getPoules } from '@/services/poules'
import { createInvolvement } from '@/services/transactions'
import { mapGetters } from 'vuex'

export default {
  name: 'LeadAssignment',
  props: {
    lead: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      values: {
        referral_yes_no: 'referral_no'
      },
      pouleData: []
    }
  },
  computed: {
    ...mapGetters('account', ['collaborator']),
    referralOptions () {
      return [
        {
          value: 'referral_no',
          id: 'referral_no',
          label: 'Geen aanbreng'
        },
        {
          value: 'referral_yes',
          id: 'referral_yes',
          label: 'Aanbreng'
        }
      ]
    },
    isIntakeLead () {
      return this.lead.stage.funnel === 1
    },
    referralSelectionComplete () {
      if (!this.isIntakeLead) return true
      return !!this.values.referral_yes_no && !!(this.values.referral_no_selection || this.values.referral_yes_selection) && !this.involvementsWithoutPoule
    },
    involvementsWithoutPoule () {
      return !!this.pouleData.some(poule => poule.poules.length === 0)
    }
  },
  methods: {
    show () {
      this.pouleData = []
      this.$refs.modal.show()
    },
    setSubRadioButtonFields () {
      if (this.values.referral_yes_no === 'referral_no') {
        this.$delete(this.values, 'referral_yes_selection')
      } else {
        this.$delete(this.values, 'referral_no_selection')
      }
    },
    getPouleOptions (poules) {
      return poules.map(poule => { return { label: poule.poule_name, value: poule.id } })
    },
    async processBuyer () {
      // Clear all buyers first to avoid duplicates
      this.pouleData = this.pouleData.filter(obj => obj.role !== 'buyer')
      if (this.values.collaborator) {
        return await this.setPouleData(this.values.collaborator, null, 'buyer')
      }
    },
    processNoReferral () {
      this.pouleData = this.pouleData.filter(obj => obj.role === 'buyer')
    },
    async processReferralByCollaborator () {
      this.pouleData = this.pouleData.filter(obj => obj.role !== 'referrer')
      if (this.values.referral_yes_selection === 'referral_by_team') return
      await this.setPouleData(this.collaborator, null, 'referrer')
    },
    async processReferralByTeam (poules) {
      const currentBuyerPoules = this.pouleData.filter(obj => obj.role === 'buyer')
      const newPouleIds = poules.map(item => item.id)

      // Retain referrers who are still selected
      const updatedReferrers = this.pouleData.filter(
        obj => obj.role === 'referrer' && newPouleIds.includes(obj.id)
      )

      // Identify newly added poules
      const existingReferrerIds = updatedReferrers.map(obj => obj.id)
      const newlyAddedPoules = poules.filter(
        poule => !existingReferrerIds.includes(poule.id)
      )

      // Update pouleData with current buyers and referrers
      this.pouleData = [...currentBuyerPoules, ...updatedReferrers]

      // Fetch poule data for newly added poules
      const promises = newlyAddedPoules.map(poule =>
        this.setPouleData(null, poule, 'referrer')
      )

      return Promise.all(promises)
    },
    async setPouleData (collaborator = null, poule = null, role) {
      if (!this.isIntakeLead) return

      let response = null

      if (collaborator) {
        const params = { query: collaborator.display_name }
        response = await getPoules({ params })
      }

      let record = {}
      if (poule) {
        record = { id: poule.id, collaborator: null, poules: [poule], role: role }
      } else {
        record = { id: null, collaborator: collaborator, poules: response.data?.results, role: role }
      }
      if (role === 'buyer') {
        // Buyer at the beginning of the list
        this.pouleData.unshift(record)
      } else {
        this.pouleData.push(record)
      }
      return response
    },
    async createInvolvementForPouleAndRole (pouleId, role) {
      const payload = {
        role: role,
        poule: pouleId,
        property: this.lead.entity
      }
      const response = await createInvolvement(payload)
      return response
    },
    async createInvolvements () {
      //  Business logic: https://dewaele.atlassian.net/browse/DEW-10245
      if (!this.isIntakeLead) return
      const promises = []
      this.pouleData.forEach(
        record => {
          let pouleId = null
          if (record.poules.length === 1) {
            const poule = record.poules[0]
            pouleId = poule.id
          } else {
            pouleId = this.values[`poule_${record.collaborator.id}`]
          }
          promises.push(this.createInvolvementForPouleAndRole(pouleId, record.role))
        }
      )
      return await Promise.all(promises)
    },
    async submit (data) {
      try {
        const payload = {
          collaborator: data.collaborator.id
        }
        const response = await assignLead(this.lead.id, payload)
        await this.createInvolvements()
        successModal(`De lead is toegewezen aan ${data.collaborator.display_name}.`)
        this.$refs.modal.hide()
        this.$emit('leadAssigned')
        return response
      } catch (error) {
        console.error(error)
        errorModal('De lead kon niet worden toegewezen')
      }
    }

  }
}
</script>
